<template>
    <v-dialog v-model="lostInternetDialog" width="600" scrollable persistent>
        <v-card class="white">
            <v-card-text class="pb-0">
                <div class="px-4 py-16 dialogBackground text-center">
                    <v-img :src="$assetURL('../../../../public/media/logo/wifi.gif')" class="wifiImage img-fluid mx-auto d-block"></v-img>
                    <div class="oopsText mt-10">OOPS🙁!
                    </div>
                    <div class="descriptionText mt-10">No internet connection found. <br />
                        Check your internet connection and try agin. </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "App",
    data() {
        return {
            lostInternetDialog: false,
        }
    },
    props: {
        dialogstatus: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        dialogstatus(param) {
            this.lostInternetDialog = param;
        }
    },
}
</script>
<style>
.dialogBackground {
    background-image: url("../../../../public/media/logo/No_net.png");
    background-size: 100% 400px;
}

.wifiImage {
    width: 170px;
}

.oopsText {
    font-size: 50px;
    font-weight: 700;
    display: block;
    font-family: cursive;
}

.descriptionText {
    font-size: 18px;
    color: #767676;
}

.metallic-emoji {
    font-size: 100px;
    background: #ffba49;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    display: inline-block;
    padding: 10px;
}
</style>